import React, { FunctionComponent } from 'react';
import { Box, Button, Grid, Typography, FormGroup, Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import SelectInput from '../../shared/components/form/SelectInput';
import TextInput from '../../shared/components/form/TextInput';
import ErrorAlert from '../../shared/components/ErrorAlert';
import { UserContext } from '../../auth/contexts/userContext';
import { ServicesContext } from '../../services/contexts/ServicesContexts';
import { ReferralsContext } from '../../referrals/contexts/ReferralsContext';
import { ServiceFormValues } from '../../services/model';
import { buildServiceTypeChoices } from '../../referrals/constants/BuildServiceTypeChoices';
import DocumentationUpload from '../../referrals/components/DocumentationUpload';
import { Student } from '../../students/model';
import { intervalChoices, frequencyCountUnitChoices } from '../../services/constants';

// TODO: Temp styles before we line things up with the mockup
const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '100%',
    margin: '10px',
  },
  baseError: {
    marginBottom: '10px',
  },
  formTitle: {
    marginLeft: '12px',
  },
});

interface NewStudentServiceFormProps {
  onSuccess?: () => void;
  student?: Student;
}

const NewStudentServiceForm: FunctionComponent<NewStudentServiceFormProps> = (props) => {
  const { onSuccess, student } = props;
  const classes = useStyles();
  const { studentId } = useParams<Record<string, string>>();
  const { currentUserHasRole } = React.useContext(UserContext);
  const [formValues, setFormValues] = React.useState<ServiceFormValues>({});
  const [choicesFetched, setChoicesFetched] = React.useState(false);
  const [connectedServices, setConnectedServices] = React.useState([]);
  const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(true);
  const [ownerQuickSearch, setOwnerQuickSearch] = React.useState('');

  const {
    createOrUpdateService,
    serviceSubmitting,
    formErrors,
    formErrorAlertVisible,
    setFormErrorAlertVisible,
    fetchChoices,
    phaseChoices,
    activeOwnerChoices,
    serviceTypeChoices,
    stageChoices,
    statusChoices,
  } = React.useContext(ServicesContext);

  const { requiredAttachment, optionalAttachedFiles } = React.useContext(ReferralsContext);

  React.useEffect(() => {
    const todaysDate = `${new Date().getFullYear()}-${
      new Date().getMonth() + 1
    }-${new Date().getDate().toString().padStart(2, '0')}`;

    setFormValues({
      referral_type: '',
      student_id: '',
      frequency: '',
      phase: '',
      service_id: '',
      referral_stage_id: '',
      owner_id: '',
      user_id: '',
      start_date: '',
      end_date: '',
      esy_start_date: '',
      esy_end_date: '',
      esy_frequency: '',
      status: '',
      frequency_count: '',
      frequency_count_units: '',
      frequency_interval: '',
      service_type_id: '',
      connected_services: [],
      connected_service_id: '',
      initial_referral_date: todaysDate,
    });
  }, []);

  React.useEffect(() => {
    if (!choicesFetched) {
      fetchChoices();
      setChoicesFetched(true);
    }
  }, [choicesFetched, setChoicesFetched, fetchChoices]);

  const checkServiceExistence = (serviceName) => {
    return student.current_service_names?.includes(serviceName);
  };

  const allAttachmentsOptional = () => {
    if (formValues.referral_type === 'iep') {
      return checkServiceExistence('IEP Meeting Participation');
    }

    if (formValues.referral_type === '504') {
      return checkServiceExistence('504 Meeting Participation');
    }

    return false;
  };

  React.useEffect(() => {
    if (formValues.referral_type === 'iep') {
      setConnectedServices([
        {
          label: checkServiceExistence('IEP Meeting Participation')
            ? 'IEP Meeting Participation (Already Exists)'
            : 'IEP Meeting Participation (Required)',
          value: 'IEP Meeting Participation',
          required: true,
        },
        { label: 'Progress Monitoring (Optional)', value: 'Progress Monitoring', required: false },
        { label: 'IEP Input Writing (Optional)', value: 'IEP Input Writing', required: false },
        { label: 'ESY Recommendation (Optional)', value: 'ESY Recommendation', required: false },
        { label: 'Access Billing (Optional)', value: 'Access Billing', required: false },
        { label: 'Support for School Personnel (Optional)', value: 'Support for School Personnel', required: false },
      ]);
    }

    if (formValues.referral_type === '504') {
      setConnectedServices([
        {
          label: checkServiceExistence('504 Meeting Participation')
            ? '504 Meeting Participation (Already Exists)'
            : '504 Meeting Participation (Required)',
          value: '504 Meeting Participation',
          required: true,
        },
        { label: '504 Modification Writing (Optional)', value: '504 Modification Writing', required: false },
      ]);
    }

    if (formValues.referral_type === 'evaluation') {
      setConnectedServices([
        { label: 'Evaluation Report Writing (Required)', value: 'Evaluation Report Writing', required: true },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  const handleFormValueChange = (key: string, value: string) => {
    if (key === 'referral_service_users_attributes') {
      if (formValues.referral_service_users_attributes === undefined) {
        formValues.referral_service_users_attributes = {};
      }

      // The logic and array manipulation here is hard to understand but it is all needed to work
      // with Rails accepts_nested_attributes_for.
      const user_id = value;
      let { referral_service_users_attributes } = formValues;

      if (
        formValues.referral_service_users_attributes?.[user_id] &&
        formValues.referral_service_users_attributes?.[user_id]?._destroy !== '1'
      ) {
        referral_service_users_attributes = {
          ...referral_service_users_attributes,
          [user_id]: { _destroy: '1', id: referral_service_users_attributes[user_id].id },
        };
      } else {
        referral_service_users_attributes = {
          ...referral_service_users_attributes,
          [user_id]: { id: referral_service_users_attributes?.[user_id]?.id, user_id },
        };
      }

      setFormValues({ ...formValues, referral_service_users_attributes });
    } else if (key === 'referral_type' && (value === 'evaluation' || value === 'screening')) {
      setFormValues({ ...formValues, [key]: value, phase: 'referral' }); // If the referral type is "Evaluation," then the phase should default to 'referral'
    } else {
      setFormValues({ ...formValues, [key]: value });
    }
  };

  const handleConnectedServicesRenewalsChange = (key: string, event: React.ChangeEvent<HTMLInputElement>) => {
    let options = [];

    if (formValues?.[key]) {
      options = formValues?.[key];
    }

    if (event.target.checked) {
      options = [...options, event.target.value.toString()];
    } else {
      const index = options.indexOf(event.target.value.toString());
      options.splice(index, 1);
    }

    setFormValues({ ...formValues, [key]: options });
  };

  const addRequiredServices = () => {
    const requiredServices = [];

    connectedServices.forEach((service) => {
      if (service.required) {
        requiredServices.push(service.value);
      }
    });

    return [...formValues.connected_services, ...requiredServices];
  };

  const handleCreateService = () => {
    const allServices = addRequiredServices();
    const attachments = [requiredAttachment, ...optionalAttachedFiles];
    createOrUpdateService({ ...formValues, connected_services: allServices }, studentId, onSuccess, null, attachments);
  };

  const requiredFormsCompleted = () => {
    if (formValues?.referral_type === undefined) {
      setButtonDisabled(true);
    } else if (formValues?.referral_type === 'evaluation') {
      setButtonDisabled(
        formValues?.service_id === undefined ||
          formValues?.initial_referral_date === undefined ||
          requiredAttachment === undefined,
      );
    } else if (formValues?.referral_type === 'screening') {
      setButtonDisabled(
        formValues?.service_id === undefined ||
          formValues?.initial_referral_date === undefined ||
          requiredAttachment === undefined,
      );
    } else if (formValues?.referral_type === 'iep') {
      if (checkServiceExistence('IEP Meeting Participation')) {
        setButtonDisabled(
          formValues?.start_date === undefined ||
            formValues?.end_date === undefined ||
            formValues?.frequency === '' ||
            formValues?.service_id === undefined,
        );
      } else {
        setButtonDisabled(
          formValues?.start_date === undefined ||
            formValues?.end_date === undefined ||
            formValues?.frequency === '' ||
            formValues?.service_id === undefined ||
            requiredAttachment === undefined,
        );
      }
    } else if (formValues?.referral_type === '504') {
      if (checkServiceExistence('504 Meeting Participation')) {
        setButtonDisabled(
          formValues?.start_date === undefined ||
            formValues?.end_date === undefined ||
            formValues?.frequency === '' ||
            formValues?.service_id === undefined,
        );
      } else {
        setButtonDisabled(
          formValues?.start_date === undefined ||
            formValues?.end_date === undefined ||
            formValues?.frequency === '' ||
            formValues?.service_id === undefined ||
            requiredAttachment === undefined,
        );
      }
    }
  };

  React.useEffect(() => {
    requiredFormsCompleted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, requiredAttachment]);

  return (
    <>
      <form noValidate autoComplete="off" style={{ marginBottom: '30px' }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <SelectInput
              styleOverrides={classes.textInput}
              type="text"
              label="Referral Type"
              errorMessage={formErrors?.service}
              value={formValues?.referral_type}
              valueChanged={(value: string) => handleFormValueChange('referral_type', value)}
              choices={[
                { label: 'Evaluation', value: 'evaluation' },
                { label: 'Screening', value: 'screening' },
                { label: 'IEP Service', value: 'iep' },
                { label: '504 Service', value: '504' },
              ]}
              defaultChoice={{ value: '', label: 'None' }}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              styleOverrides={classes.textInput}
              type="text"
              label="Service Type"
              errorMessage=""
              value={(formValues as any)?.service_id}
              valueChanged={(value: string) => handleFormValueChange('service_id', value)}
              choices={buildServiceTypeChoices(formValues?.referral_type, serviceTypeChoices)}
              defaultChoice={{ value: '', label: 'None' }}
            />
          </Grid>
        </Grid>
        {formValues?.service_id !== '' && (
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <SelectInput
                styleOverrides={classes.textInput}
                type="text"
                label="Phase"
                errorMessage={formErrors?.phase}
                value={formValues?.phase || 'Student'}
                valueChanged={(value: string) => handleFormValueChange('phase', value)}
                choices={phaseChoices}
                defaultChoice={{ value: '', label: 'None' }}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                styleOverrides={classes.textInput}
                type="text"
                label="Status"
                errorMessage={formErrors?.status}
                value={formValues?.status || 'active'}
                valueChanged={(value: string) => handleFormValueChange('status', value)}
                choices={statusChoices}
                defaultChoice={{ value: '', label: 'None' }}
              />
            </Grid>
            <Grid item xs={12}>
              {currentUserHasRole('admin') && (
                <SelectInput
                  styleOverrides={classes.textInput}
                  type="text"
                  label="Stage"
                  errorMessage={formErrors?.referral_stage}
                  value={formValues?.referral_stage_id || '1'}
                  valueChanged={(value: string) => handleFormValueChange('referral_stage_id', value)}
                  choices={stageChoices}
                  defaultChoice={{ value: '', label: 'None' }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2" component="h2" style={{ marginLeft: 10 }}>
                Service Dates
              </Typography>
            </Grid>

            {(formValues?.referral_type === 'evaluation' || formValues?.referral_type === 'screening') && (
              <Grid item xs={12}>
                <TextInput
                  styleOverrides={classes.textInput}
                  label="Date Of Receipt Of Prior Written Notice"
                  type="date"
                  shrinkLabel
                  errorMessage={formErrors?.initial_referral_date}
                  value={formValues?.initial_referral_date}
                  valueChanged={(value: string) => setFormValues({ ...formValues, initial_referral_date: value })}
                />
              </Grid>
            )}
            {(formValues?.referral_type === 'iep' || formValues?.referral_type === '504') && (
              <>
                <Grid item xs={6}>
                  <TextInput
                    styleOverrides={classes.textInput}
                    label="Date Services Begin"
                    type="date"
                    shrinkLabel
                    errorMessage={formErrors?.start_date}
                    value={formValues?.start_date}
                    valueChanged={(value: string) => setFormValues({ ...formValues, start_date: value })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    styleOverrides={classes.textInput}
                    label="Date Services End"
                    type="date"
                    shrinkLabel
                    errorMessage={formErrors?.end_date}
                    value={formValues?.end_date}
                    valueChanged={(value: string) => setFormValues({ ...formValues, end_date: value })}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Typography variant="h2" component="h2" style={{ marginLeft: 10 }}>
                Service Frequency
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextInput
                styleOverrides={classes.textInput}
                type="text"
                label="Frequency"
                errorMessage={formErrors?.frequency}
                value={formValues?.frequency}
                valueChanged={(value: string) => handleFormValueChange('frequency', value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item>
                  <TextInput
                    styleOverrides={classes.textInput}
                    type="text"
                    label="Frequency Amount"
                    value={formValues?.frequency_count}
                    valueChanged={(value: string) => handleFormValueChange('frequency_count', value)}
                  />
                </Grid>
                <Grid item style={{ minWidth: 225 }}>
                  <SelectInput
                    styleOverrides={classes.textInput}
                    type="text"
                    label="Frequency Units"
                    choices={frequencyCountUnitChoices}
                    value={formValues?.frequency_count_units}
                    valueChanged={(value: string) => handleFormValueChange('frequency_count_units', value)}
                  />
                </Grid>
                <Grid item style={{ margin: '30px 20px 0', alignItems: 'center' }}>
                  <Typography style={{ textAlign: 'center' }}>Per</Typography>
                </Grid>
                <Grid item style={{ minWidth: 225 }}>
                  <SelectInput
                    styleOverrides={classes.textInput}
                    type="text"
                    label="Frequency Interval"
                    choices={intervalChoices}
                    value={formValues?.frequency_interval}
                    valueChanged={(value: string) => handleFormValueChange('frequency_interval', value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h2" component="h2" style={{ marginLeft: 10 }}>
                  ESY Details
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  styleOverrides={classes.textInput}
                  type="text"
                  label="ESY Frequency"
                  errorMessage={formErrors?.esy_frequency}
                  value={formValues?.esy_frequency}
                  valueChanged={(value: string) => handleFormValueChange('esy_frequency', value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  styleOverrides={classes.textInput}
                  label="ESY Start Date"
                  type="date"
                  shrinkLabel
                  errorMessage={formErrors?.esy_start_date}
                  value={formValues?.esy_start_date}
                  valueChanged={(value: string) => handleFormValueChange('esy_start_date', value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  styleOverrides={classes.textInput}
                  label="ESY End Date"
                  type="date"
                  shrinkLabel
                  errorMessage={formErrors?.esy_end_date}
                  value={formValues?.esy_end_date}
                  valueChanged={(value: string) => handleFormValueChange('esy_end_date', value)}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {currentUserHasRole('admin') && activeOwnerChoices && (
                <Grid container spacing={1} style={{ marginLeft: '10px', marginTop: '20px' }}>
                  <Grid item xs={12}>
                    <Typography variant="h3" component="h3">
                      Select Providers
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextInput
                      label="Quick filter by provider name..."
                      value={ownerQuickSearch}
                      valueChanged={(value: string) => setOwnerQuickSearch(value)}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <FormGroup row>
                      <Grid container>
                        {activeOwnerChoices
                          .filter((owner) => owner.label.toLowerCase().includes(ownerQuickSearch.toLowerCase()))
                          .filter((owner) => !owner.label.toLowerCase().includes('district'))
                          .filter((owner) => !owner.label.toLowerCase().includes('academy'))
                          .map((owner: any) => (
                            <Grid component={Box} key={owner.value} item xs={12} sm={3} display="inherit">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name={owner.label}
                                    value={owner.value}
                                    color="primary"
                                    onChange={() =>
                                      handleFormValueChange('referral_service_users_attributes', owner.value)
                                    }
                                  />
                                }
                                label={owner.label}
                                checked={
                                  formValues?.referral_service_users_attributes?.[owner?.value] !== undefined &&
                                  formValues?.referral_service_users_attributes?.[owner?.value]?._destroy !== '1'
                                }
                              />
                            </Grid>
                          ))}
                      </Grid>
                    </FormGroup>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              {connectedServices.length > 0 && (
                <Grid container spacing={1} style={{ marginLeft: '10px', marginTop: '20px' }}>
                  <Grid item xs={12}>
                    <Typography variant="h1" component="h1">
                      Include Secondary Services
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h3" component="h3">
                      Select All That Apply
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormGroup row>
                      <Grid container>
                        {connectedServices.map((option: Record<string, string | boolean>) => (
                          <Grid component={Box} key={option.value as string} item xs={12} sm={8} display="inherit">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name={option.label as string}
                                  disabled={option.required as boolean}
                                  value={option.value as string}
                                  color="primary"
                                  onChange={(event) =>
                                    handleConnectedServicesRenewalsChange('connected_services', event)
                                  }
                                />
                              }
                              label={option.label}
                              checked={
                                (!student?.current_service_names?.includes(option?.value as string) &&
                                  (option.required as boolean)) ||
                                formValues?.connected_services?.includes(option?.value?.toString())
                              }
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                  </Grid>
                </Grid>
              )}
              {formValues?.referral_type && (
                <DocumentationUpload
                  category={formValues?.referral_type}
                  allAttachmentsOptional={allAttachmentsOptional()}
                />
              )}
            </Grid>
          </Grid>
        )}
      </form>
      {formErrors?.base && (
        <ErrorAlert display={formErrorAlertVisible} onClose={() => setFormErrorAlertVisible(false)}>
          {formErrors?.base}
        </ErrorAlert>
      )}

      <Button
        disabled={buttonDisabled || serviceSubmitting}
        style={{ marginLeft: 7 }}
        variant="contained"
        color="primary"
        onClick={handleCreateService}
      >
        {serviceSubmitting ? 'Saving...' : 'Save Service'}
      </Button>
    </>
  );
};

export default NewStudentServiceForm;
