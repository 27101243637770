import React, { useState, createContext } from 'react';
import axios from '../../shared/utils/axios.utils';
import { ServiceChoicesResponse, Service } from '../model';
import { SelectOption } from '../../shared/common.model';
import { Colors } from '../../shared/style/colors';

const ServiceFormOptionsContext = createContext({
  fetchChoices: undefined,
  schoolChoices: [],
  phaseChoices: [],
  serviceTypeChoices: [],
  stageChoices: [],
  ownerChoices: [],
  frequencyChoices: [],
  statusChoices: [
    { label: 'Active', value: 'active' },
    { label: 'Complete', value: 'complete' },
  ],

  statusColors: {
    'On Target': Colors.Success,
  },
  phaseColors: {
    referral: Colors.PurpleThree,
    student: Colors.Success,
    patient: Colors.Success,
    cancelled: Colors.Red,
    on_hold: Colors.Orange,
  },
  connectedServiceChoices: [],
});

const ServiceFormOptionsContextConsumer = ServiceFormOptionsContext.Consumer;
const ServiceFormOptionsContextProvider = ({ children }) => {
  const [schoolChoices, setSchoolChoices] = useState<SelectOption[]>([]);
  const [phaseChoices, setPhaseChoices] = useState<SelectOption[]>([]);
  const [serviceTypeChoices, setServiceTypeChoices] = useState<SelectOption[]>([]);
  const [stageChoices, setStageChoices] = useState<SelectOption[]>([]);
  const [ownerChoices, setOwnerChoices] = useState<SelectOption[]>([]);
  const [frequencyChoices, setFrequencyChoices] = useState<SelectOption[]>([]);
  const [connectedServiceChoices, setConnectedServiceChoices] = useState<SelectOption[]>([]);

  const [statusChoices] = useState([
    { label: 'Active', value: 'active' },
    { label: 'Complete', value: 'complete' },
  ]);

  const [statusColors] = useState({
    'On Target': Colors.Success,
  });

  const [phaseColors] = useState({
    referral: Colors.PurpleThree,
    student: Colors.Success,
    patient: Colors.Success,
    cancelled: Colors.Red,
    on_hold: Colors.Orange,
  });

  const fetchChoices = async (service?: Service) => {
    const connectedServices = service?.connected_service_options;
    setConnectedServiceChoices(connectedServices);

    axios.get<string, ServiceChoicesResponse>(`referral_services/new.json`).then((r: ServiceChoicesResponse) => {
      const { phase_options, service_types, stage_options, owners, all_schools, frequency_options } = r.data?.result;

      setSchoolChoices(all_schools);
      setPhaseChoices(phase_options);
      setServiceTypeChoices(service_types);
      setStageChoices(stage_options);
      setOwnerChoices(owners);
      setFrequencyChoices(frequency_options);
    });
  };

  return (
    <ServiceFormOptionsContext.Provider
      value={{
        fetchChoices,
        schoolChoices,
        phaseChoices,
        serviceTypeChoices,
        stageChoices,
        ownerChoices,
        frequencyChoices,
        statusChoices,
        statusColors,
        phaseColors,
        connectedServiceChoices,
      }}
    >
      {children}
    </ServiceFormOptionsContext.Provider>
  );
};

export { ServiceFormOptionsContextProvider, ServiceFormOptionsContextConsumer, ServiceFormOptionsContext };
